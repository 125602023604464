import * as React from "react";

import { StaticImage } from 'gatsby-plugin-image';


const Score = (props) => {
  const {score = 0} = props;

  const imageScore = () => {
    let response
    if(!score) {
      response = <StaticImage src="../images/report/score/sin-score.png" className="img-bc-score" objectFit="contain" alt="Sin score" />
    }
    if(score > 0 && score <= 549) {
      response = <StaticImage src="../images/report/score/muy-bajo.png" className="img-bc-score" objectFit="contain" alt="Muy bajo" />
    }
    if(score >= 550 && score <= 619) {
      response = <StaticImage src="../images/report/score/bajo.png" className="img-bc-score" objectFit="contain" alt="Bajo" />
    }
    if(score >= 620 && score <= 649) {
      response = <StaticImage src="../images/report/score/regular.png" className="img-bc-score" objectFit="contain" alt="Regular" />
    }
    if(score >= 650 && score <= 699) {
      response = <StaticImage src="../images/report/score/bueno.png" className="img-bc-score" objectFit="contain" alt="Bueno" />
    }
    if(score >= 700 && score <= 749) {
      response = <StaticImage src="../images/report/score/muy-bueno.png" className="img-bc-score" objectFit="contain" alt="Muy bueno" />
    }
    if(score >= 750) {
      response = <StaticImage src="../images/report/score/excelente.png" className="img-bc-score" objectFit="contain" alt="Excelente" />
    }
    return response
  }

  const textScore = () => {
    let response
    if(!score) {
      response = "Sin Score"
    }
    if(score > 0 && score <= 549) {
      response = "Muy Bajo"
    }
    if(score >= 550 && score <= 619) {
      response = "Bajo"
    }
    if(score >= 620 && score <= 649) {
      response = "Regular"
    }
    if(score >= 650 && score <= 699) {
      response = "Bueno"
    }
    if(score >= 700 && score <= 749) {
      response = "Muy Bueno"
    }
    if(score >= 750) {
      response = "Excelente"
    }
    return response
  }

  return (
    <>
      {
        imageScore()
      }

      <p className="score">{score}</p>

      <span className="score-text">
        { textScore() }
      </span>
    </>
  )
}

export default Score
