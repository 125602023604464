import React, { useState, useEffect } from "react"
import { useQueryParam, StringParam } from "use-query-params";

import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es'

import { Link } from "gatsby-theme-material-ui";

import { StaticImage } from 'gatsby-plugin-image';

import Layout from "../layout"
import Score from "../components/score";

import '../styles/app.scss';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import AddCardIcon from '@mui/icons-material/AddCard';

import LinearProgressCustom from "../components/loading";
import { Typography } from "@mui/material";

let formatter = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});

const ResultPage = (props) => {
  const data = props?.location?.state?.userData;
  const [idReport] = useQueryParam("id", StringParam);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [report, setReport] = useState(null);
  const [resumen, setResumen] = useState({
    score: 0,
    deudaMensual: 0,
    cuentasAtrasadas: 0,
    cuentasAbiertas: 0
  });

  useEffect(() => {
    // console.log('reporte: ', report)
    // console.log('data: ', data)

    if (report === null) {
      if (data) {
        handleCredit()
      } else if (idReport) {
        handleGetCredit()
      } else {
        // console.log('redirect')
        setLoading(false)
        setError(true)

      }
    } else {
      setLoading(false)

      let deuda = 0;
      let cuentasAtrasadas = 0;
      let cuentasAbiertas = 0;
      let salario = report?.persona?.salario;

      report?.creditos?.map((credit, i) => {
        deuda += handleDeuda(credit?.montoPagar, credit?.frecuenciaPagos);
        cuentasAtrasadas = moment().diff(credit?.fechaUltimoPago, 'months') < 12 && (credit?.numeroPagosVencidos > 0 || credit?.saldoVencido > 0) ? cuentasAtrasadas + 1 : cuentasAtrasadas;
        cuentasAbiertas = moment().diff(credit?.fechaAperturaCuenta, 'months') < 12 ? cuentasAbiertas + 1 : cuentasAbiertas;
      });

      let porcentajeEndeudamiento = (deuda ? (deuda / salario) * 100 : 0).toFixed(1);

      setResumen({
        ...resumen,
        score: report?.scores[report?.scores?.length - 1]?.valor,
        deudaMensual: porcentajeEndeudamiento,
        cuentasAtrasadas: cuentasAtrasadas,
        cuentasAbiertas: cuentasAbiertas
      })
    }

  }, [report])

  const handleGetCredit = async () => {
    setLoading(true)

    let token = '123456';

    let config = {
      headers: {
        'x-access-token': token
      },
      onDownloadProgress: e => {
        let time = (e.loaded * 100) / e.total;
        setProgress(time)
        // console.log('progress... ', time)
        // console.log('tets', e.lengthComputable)
      }
    }

    let response = await axios.get(
      `/api/v1/credito/${idReport}`,
      config
    )

    if (response?.status !== 200 || response?.data == null) {
      setError(true)
    } else {
      setReport(response?.data)
    }

    // console.log('respuesta', response)
  }

  const handleDeuda = (monto, pagos) => {
    let periodo = {
      A: 0.08,
      B: 0.5,
      C: 2,
      D: 1,
      E: 0.01,
      M: 1,
      Q: 2,
      R: 1,
      S: 4,
      T: 0.33,
      U: 1
    }

    let total = monto * periodo[pagos]
    return total
  }

  const handleCredit = async () => {
    setLoading(true)

    let token = '123456';

    let config = {
      headers: {
        'x-access-token': token
      },
      onDownloadProgress: e => {
        let time = (e.loaded * 100) / e.total
        setProgress(time)
        // console.log('progress... ', time)
      }
    }

    let response = await axios.post(
      '/api/v1/credito',
      data,
      config
    )

    // console.log('respuesta', response)

    if (response?.status !== 200 || response?.data == null) {
      setError(true)
    } else {
      setReport(response?.data)
    }
    setLoading(false)
  }

  const handleCapacidadDePago = () => {
    let value = 0;
    let salario = data?.persona?.salario || report?.persona?.salario;
    salario = salario / 2;
    let capacidad = 0;
    let deuda = 0;

    report?.creditos.map((credit, i) => {
      // deuda += credit?.montoPagar * 1;
      deuda += handleDeuda(credit?.montoPagar || 0, credit?.frecuenciaPagos);
    });

    if (report?.servicios?.proposito == "Obtener liquidez") {
      capacidad = salario;
    } else {
      capacidad = salario - deuda
    }

    value = capacidad > 0 ? capacidad * 100 : 0;

    value = formatter.format(value);
    return value
  }

  return (
    <Layout>
      {
        loading
          ?
          <div className="wrapper-loading">
            <Box className="spinner">

              <LinearProgressCustom progress={progress} />

            </Box>

            <Box>
              <h2>Estamos procesando tu solicitud...</h2>
              <p>Espera unos segundos</p>
            </Box>
          </div>

          :
          error || !report
            ?
            <div className="wrapper-loading">
              <Box></Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <h2>Algo salio mal</h2>
                <p>Lo sentimos, no podemos continuar con tu solicitud en este momento, vuelve a intentarlo mas tarde.</p>
                <Button sx={{ color: '#fff' }} variant="contained" component={Link} to="/precalificar">Precalificar</Button>
              </Box>
            </div>
            :
            <div className="wrapper-status">
              <Box className="status">
                <Box
                  sx={{
                    maxWidth: 300,
                    width: "100%"
                  }}
                >
                  <StaticImage src="../images/report/report.svg" objectFit="contain" alt="Report" />
                </Box>
                <p className="title">La solicitud ha terminado.</p>
                {
                  resumen?.score > 550
                    ? <p className="text"><b>FELICIDADES!</b> <br /> tu crédito <b>preautorizado</b> es de: <b>{handleCapacidadDePago()}.</b><br /> Un asesor se comunicará contigo a la brevedad posible para continuar con tu tramite. </p>
                    : <p className="text">En este momento no eres candidato para un crédito hipotecario bancario, aun puedes mejorar tu historial crediticio y/o tu capacidad de pago y volver a aplicar.</p>
                }
              </Box>

              <Paper className="bc-score" elevation={0}>
                <p className="title">Tu calificacion crediticia:</p>

                <Score score={resumen?.score} />

              </Paper>

              <Box className="bc-score-info">
                {
                  idReport
                    ?
                    <Box
                      sx={{
                        // background: '#fafafa',
                        // px: 1.5,
                        // py: 2,
                        // borderRadius: 1
                      }}
                    >
                      <Typography pb={2} variant="title" component="h3">Información del cliente</Typography>
                      <Typography
                        sx={{
                          textTransform: "capitalize"
                        }}
                      >Nombre: {report?.persona?.primerNombre} {report?.persona?.segundoNombre} {report?.persona?.apellidoPaterno} {report?.persona?.apellidoMaterno}</Typography>

                      <Typography
                      >Celular: {report?.contacto?.codigoPais} {report?.contacto?.celular}</Typography>

                      <Typography
                      >Correo: {report?.contacto?.correo}</Typography>
                    </Box>
                    : null
                }

                <Typography py={2} variant="title" component="h3">Resumen de historial crediticio</Typography>

                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <DataUsageIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`Deuda mensual = ${resumen?.deudaMensual}%`} secondary={`El ${resumen?.deudaMensual}% de tus ingresos mensuales son utilizados para pagar tus deudas.`} />
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <CreditCardOffIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`Creditos retrasados = ${resumen?.cuentasAtrasadas}`} secondary={`De los ultimos 12 meses, ${resumen?.cuentasAtrasadas} crédito cuenta con retraso en pagos.`} />
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <AddCardIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`Aperturas de crédito = ${resumen?.cuentasAbiertas}`} secondary={`En los ultimos 12 meses has abierto ${resumen?.cuentasAbiertas} nuevos créditos.`} />
                  </ListItem>
                </List>
              </Box>

            </div>
      }
    </Layout>
  )
}

export default ResultPage
